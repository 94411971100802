.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: auto;
}

.Item {
  border-color: #99fb61;
  margin: auto;
  flex-grow: 4;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  margin: 50px;
}



